export default {
	baseStyle: {
		list: {
			background: 'white.100',
			color: '#4F4F4F',
			borderRadius: '0.8rem',
			borderWidth: '0',
			_focus: {
				boxShadow: '0',
			},
			p: '1rem 0',
		},
		item: {
			color: '#4f4f4f',
			fontWeight: 'bold',
			paddingInlineStart: '1rem',
			paddingInlineEnd: '1rem',
			borderRadius: '0',
			alignItems: 'normal',
			_hover: {
				background: '#ffffff91',
			},
			_active: {
				background: '#ffffff91',
				opacity: '0.866',
			},
			_focus: {
				background: '#ffffff91',
			},
		},
		divider: {
			borderColor: '#4f4f4f54',
		},
	},
}