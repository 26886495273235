export default {
	baseStyle: {
		thumb: {
			bg: '#4F4F4F',
		},
		track: {
			bg: '#ffffffd6',
			_checked: {
				bg: 'bluish.300',
			},
			_focus: {
				boxShadow: '0 0 0 3px #7b7ce0',
			},
		},
	},
}