export default {
	baseStyle: {
		content: {
			background: 'white.100',
			color: '#4F4F4F',
			borderRadius: '0.8rem',
			_focus: {
				boxShadow: '0',
			},
		},
		header: {
			fontWeight: 'bold',
			borderColor: '#00000017',
		},
		footer: {
			borderColor: '#00000017',
		},
		closeButton: {
			_focus: {
				boxShadow: '0 0 0 3px #7b7ce0',
			},
		},
	},
}